@import "variables";

// #region Colors

$primary: $brand-color;

$secondary: $secondary-brand-color;

// #endregion

// #region Fonts

$headings-font-family: $brand-font-family-headings;

$font-family-sans-serif: $brand-font-family-text;

// #endregion

input[type="search"]::-webkit-search-cancel-button {
	display: none;
}

// https://github.com/webpack-contrib/sass-loader#resolving-import-at-rules
@import "~bootstrap/scss/bootstrap.scss";
