.itemDetails {
	display: flex;
	flex-direction: column;
	row-gap: 1rem;
}

.ellipsisOverflow {
	/* future: replace with https://www.w3.org/TR/css-overflow-3/#line-clamp */
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;
}

.singleColumnLayout {
	display: flex;
	flex-direction: column;
	gap: 1rem;
}

.twoColumnLayout {
	display: grid;
	grid: auto-flow / repeat(2, 1fr);
	align-items: center;
	column-gap: 1rem;
}

.formLabel {
	font-size: 1.5rem;
}

.employee {
	display: flex;
	flex-direction: column;
}

.isRoomRessource {
	min-width: 5rem;
}

.history {
	display: flex;
	flex-direction: column;
	row-gap: 0.5rem;
}

.historyItemContent {
	white-space: pre-wrap;
}

.edit {
	padding: 0.1em 0;
}
