@import "../../style/variables";

.search:focus {
	box-shadow: unset !important;
}

.clearSearch {
	background-color: transparent !important;
	color: $brand-color !important;
	border-left: 0 !important;
	border-top-color: #ced4da !important;
	border-bottom-color: #ced4da !important;
}

.search:focus + * > .clearSearch {
	border-top-color: lighten($brand-color, 25%) !important;
	border-bottom-color: lighten($brand-color, 25%) !important;
}
