.navigationMobile {
	display: flex;
	flex-direction: column;
	row-gap: 0.5rem;
}

$navigation-button-vertical-padding: 1rem;

.navigationLink {
	padding-top: $navigation-button-vertical-padding  !important;
	padding-bottom: $navigation-button-vertical-padding  !important;
}

.navigationLinkText {
	font-size: 2rem;
}

.itemsWithSameStateLinks {
	display: grid;
	grid: auto-flow / repeat(3, 1fr);
	column-gap: 0.5rem;
	row-gap: 0.5rem;
}
