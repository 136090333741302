.structureGroups {
	display: flex;
	flex-direction: column;
	gap: 1rem;
}

.structures {
	display: grid;
	grid: 1fr / repeat(auto-fit, minmax(0, 1fr));
	column-gap: 0.5rem;
}

.structure {
	padding-left: 0 !important;
	padding-right: 0 !important;
}

.rooms {
	display: grid;
	gap: 0.5rem;
	grid: auto-flow / repeat(12, 1fr);
}

.fullWidthColumn {
	grid-column: span 12;
}

.halfWidthColumn {
	grid-column: span 6;
}

.thirdWidthColumn {
	grid-column: span 4;
}

.quarterWidthColumn {
	grid-column: span 3;
}
