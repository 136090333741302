.form,
.singleColumnLayout {
	display: flex;
	flex-direction: column;
	gap: 1rem;
}

.formLabel {
	font-size: 1.5rem;
}

.textboxContainer {
	display: block;
}

.textbox {
	display: block;
	width: 100%;
}

.twoColumnLayout {
	display: grid;
	grid: auto-flow / repeat(2, 1fr);
	align-items: center;
	column-gap: 1rem;
}

.noRowGap {
	row-gap: 0;
}

.fullWidthGridItem {
	grid-column: 1 / -1;
}

.isRoomRessource {
	min-width: 5rem;
}

.storageRooms {
	display: grid;
	grid: auto-flow / repeat(4, minmax(0, 1fr));
	gap: 0.5rem;
}

.storageRoom {
	white-space: pre-line;
}

.freeFormText {
	resize: none;
	min-width: 100%;
}

.errorDescription {
	color: hsl(0, 100%, 50%);
	border: thin solid currentColor;
}

.history {
	display: flex;
	flex-direction: column;
	row-gap: 0.5rem;
}
