.fullWidthLayout {
	display: flex;
	flex-direction: column;
}

.gridBigGap {
	gap: 1rem;
}

.gridSmallGap {
	gap: 0.5rem;
}
