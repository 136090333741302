.employeeDataLayout {
	display: grid;
	grid: 1fr / repeat(3, 1fr);
	column-gap: 1rem;
}

.employeeTeamsLink {
	min-height: 100%;
	display: flex;
	place-items: center;
}

.employeeTeamsLinkIcon {
	flex-grow: 1;
}

.items {
	margin-top: 1rem;
	display: flex;
	flex-direction: column;
	row-gap: 0.5rem;
}
