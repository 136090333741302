@import "../../style/variables";

.header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	color: $brand-color;
}

.headerItem {
	flex: 0 0 2rem;
}

.goBackIcon,
.menuOpenerIcon {
	cursor: pointer;
}

.menuOpener {
	text-align: right;
}
