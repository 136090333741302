.item {
	display: grid;
	grid: 1fr / auto 1fr 2rem;
	text-align: start;
	min-height: calc(1.5em * 3);
}

.itemData {
	display: flex;
	flex-direction: column;
}

.ellipsisOverflow {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.toItem {
	display: flex;
	align-items: center;
	justify-content: flex-end;
}
