.searchResultItemLayout {
	display: flex;
	text-align: start;
	min-height: calc(1.5em * 3);
}

.searchResultItem {
	display: flex;
	flex-direction: column;
}

.ellipsisOverflow {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
