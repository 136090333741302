.employeeItemsComponent {
	display: flex;
	flex-direction: column;
}

.employeeItemsOpenerIcon {
	align-self: center;
	min-width: 4rem;
	min-height: 2rem;
	display: flex;
	justify-content: center;
	align-items: flex-end;
}

@media (prefers-reduced-motion: no-preference) {
	.employeeItemsContainer {
		transition: height 500ms;
	}
}

.employeeItems {
	margin-top: 0.5rem;
	display: flex;
	flex-direction: column;
	row-gap: 0.5rem;
}
