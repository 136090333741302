.searchResultCategories {
	display: flex;
	flex-direction: column;
	row-gap: 2rem;
}

.room {
	display: inline-block;
	margin-right: 0.5rem;
	margin-bottom: 1rem;
}
