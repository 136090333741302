.beamerLists {
	display: flex;
	flex-direction: column;
	gap: 1rem;
}

.beamerDetails {
	display: flex;
	flex-direction: column;
}
