@import "../../style/variables";

.intersectionObserverTarget {
	position: absolute;
	top: 0;
	left: 0;
	max-width: 0;
	max-height: 0;
}

/* #region side menu */

.menu {
	background-color: scale-color($color: $brand-color, $saturation: -80%);
	padding: 2rem 0 0 1rem;
}

.item {
	outline: 0;
}

.sideBarClose {
	background-color: $secondary-brand-color;
}

/* #endregion */

.container {
	padding: 1rem;
	display: flex;
	flex-direction: column;
	row-gap: 2rem;
}

.header {
	grid-column: 1 / -1;
}

.searchTopMarginInRootOnly {
	margin-top: 2rem;
}

/* #region Desktop*/

.containerDesktop {
	padding: 1rem;
	max-height: 100vh;
	display: grid;
	grid: auto auto 1fr / repeat(14, 1fr);
	column-gap: 2rem;
	row-gap: 1rem;
}

.desktopHeaderItem {
	grid-column: span 2;
}

.desktopFilters,
.desktopContent {
	grid-column: span 7;
}

.desktopFilters {
	overflow-y: auto;
	scrollbar-gutter: stable;
	display: flex;
	flex-direction: column;
	row-gap: 1rem;
}

.desktopContent {
	overflow-y: auto;
	scrollbar-gutter: stable;
}

/* #endregion */

.webApiRequestError {
	display: flex;
	justify-content: center;
	align-items: center;
	color: red;
	padding: 1rem;
}
