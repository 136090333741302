.removedStylesBootstrapButton {
	display: block;
	padding-top: 0 !important;
	padding-left: 0 !important;
	padding-bottom: 0 !important;
	border-top-width: 0 !important;
	border-left-width: 0 !important;
	border-bottom-width: 0 !important;
}

.container {
	display: flex;
}

.employee {
	flex-grow: 1;
	margin: 0.5rem 0;
	display: flex;
}

.photo {
	max-width: 6.5rem;
	max-height: 6.5rem;
}

.data {
	flex: 1 0 0;
	margin-left: 0.5rem;
	display: flex;
	flex-direction: column;
	text-align: start;
}

.ellipsisOverflow {
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
	min-width: 0;
}

.description {
	font-size: 1rem;
	min-height: 1.5rem;
}

.toEmployee {
	flex: 0 0 2rem;
	display: flex;
	align-items: center;
	justify-content: flex-end;
}
